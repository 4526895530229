    html, body {
        height: 100%;
        margin: 0;
        padding: 0;
    }
  
    .App {
        height: 100%;
        width: 100%;
    }
  
    *{
        -ms-overflow-style: none;
    }

    ::-webkit-scrollbar {
        display: none;
    }